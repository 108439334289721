
import {tns} from "tiny-slider";


const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

if(params && params.utm_source) {
    let query = ''
    for (let key in params) {
        if(query === '') query = '?' + key + '=' + params[key]
        else query = query + '&' + key + '=' + params[key]
    }

    let allLink = document.querySelectorAll('.linkJsParams')
    allLink.forEach(el=>{
        el.href = el.href + query
    })
}

let sliderPage = document.querySelector('.useSlider')
if(sliderPage) {
    let slider = tns({
        "container": '.useSlider',
        "autoWidth": true,
        "autoHeight": false,
        "items": 5,
        "gutter": 40,
        "mouseDrag": true,
        "swipeAngle": false,
        "speed": 400,
        "responsive": {
            "800": {
                "items": 2,
                "autoWidth": true,
            },
            "600": {
                "items": 1,
                "autoWidth": true,
            }
        },
        loop: true,
        autoplay: true,
        controls: false,
        autoplayButtonOutput: false
    });
}

let infoButton = document.querySelectorAll('.navItem');
let elemSection = document.querySelectorAll('.contentSystem');
if(infoButton.length > 0) {
    elemSection[0].style.display = 'block'
    infoButton[0].classList.add("active");
    infoButton.forEach((el,index) => {
        el.addEventListener('click', ()=>{
            elemSection.forEach(section => {
                section.style.display = 'none'
            });
            elemSection[index].style.display = 'block'

            infoButton.forEach(button => {
                button.classList.remove("active");
            });
            el.classList.add("active");
        })
    })
}


let searchBurger = document.querySelector('.burger');
let openMenu = false;
let elemMenu = document.querySelector('.menuMobile');
let elemLinkMobile = document.querySelectorAll('.linkMobile');

elemLinkMobile.forEach(el=> {
    el.addEventListener('click', ()=> {
        openMenu = false;
        elemMenu.style.display = 'none'
    })
})

searchBurger.addEventListener('click', ()=>{
    if(openMenu) {
        elemMenu.style.display = 'none'
        openMenu = false;
    } else {
        elemMenu.style.display = 'block';
        openMenu = true;
    }
});
let form = {
    name: '',
    secondName: '',
    email: '',
    phone: '',
    question: '',
}

//форма

const sendEmail = () =>{
    form.name = document.querySelector('.nameInput').value;
    form.secondName = document.querySelector('.secondNameInput').value;
    form.email = document.querySelector('.emailInput').value;
    form.phone = document.querySelector('.phoneInput').value;
    form.question = document.querySelector('.questionInput').value;

    if ("" !== form.email || "" !== form.phone) {
        try {
            let t = new XMLHttpRequest
                , o = JSON.stringify(form);
            t.open("POST", "/send.php", !0),
                t.setRequestHeader("Content-type", "application/x-www-form-urlencoded"),
                t.send(o),
                alert("Ожидайте ответа специалиста")
        } catch (t) {
            alert("Произошла ошибка")
        }
    } else {
        alert("Укажите предпочитаемый вид связи")
    }
}


let buttonSend = document.querySelector('.sendButton');
buttonSend.addEventListener('click', sendEmail)
